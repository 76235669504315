module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ac430bed6703426e9624529e4f1a414f@o357582.ingest.sentry.io/5274922","release":"c19576e891762bbb4a2b2073cd4fd76de37f7d96","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NQZ1KLS8RP"],"gtagConfig":{"optimize_id":"OPT-NRGS748"},"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"428813747553330"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Work Sans:400,700,900","Lato:400,700,900&display=swap","Nanum Pen Script:400&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Meerkat","short_name":"Meerkat","start_url":"/","background_color":"white","theme_color":"white","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://my.meerkat.co.za"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
